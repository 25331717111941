import React from "react";
import styled from "styled-components";

const WorkBox = ({ title, img, description, url }) => {
  return (
    <Box>
      <Content>
        <Title>{title}</Title>
        <Picture src={img} />
        <Line />
        <DescriptionBox>{description}</DescriptionBox>
      </Content>
      <Link href={url}>Consultar por un trabajo similar</Link>
    </Box>
  );
};

const Link = styled.a`
  display: flex;
  text-decoration: none;
  justify-content: center;
  width: 85%;
  min-height: 5vh;
  text-align: center;
  align-items: center;
  border-radius: 4px;
  margin: 0px 5px 10px 5px;
  background: #f50b0b;
  color: white;
  border: none;
  align-self: center;
  font-size: 0.7em;
  font-family: "Helvetica";
  text-transform: uppercase;
  font-weight: bold;
  outline: none;
  transition: all 0.6s;
  cursor: pointer;
  @media (max-width: 813px) {
    font-size: 0.6em;
  }
`;

const DescriptionBox = styled.p`
  font-family: "Helvetica";
  font-size: 1em;
  padding: 10px;
  height: 17%;
  color: #6e6e6e;
  margin: 2px 5px 12px 8px;
  text-align: left;
  align-items: center;
`;

const Line = styled.hr`
  display: block;
  border: none;
  margin-bottom: 15px;
  margin-top: 28px;
  border-top: 1px solid #eaeaea;
  position: relative;
  width: 85%;
  transition: all 0.6s;
`;

const Picture = styled.img`
  max-height: 40%;
  @media (max-width: 813px) {
    width: 80%;
  }
`;

const Title = styled.p`
  font-family: "Helvetica";
  font-size: 1em;
  font-weight: bold;
  text-transform: uppercase;
  color: #484848;
  margin: 20px 7px 20px 7px;
  text-align: center;
  transition: all 0.6s;
  :hover {
    color: white;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 5px 10px 0px 10px;
  max-height: 400px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px #dbdada;
  transition: all 0.6s;
  :hover {
    background: black;
    color: white;
  }
  &:hover ${Link} {
    background: white;
    color: black;
  }
  &:hover ${DescriptionBox} {
    color: white;
  }
  &:hover ${Title} {
    color: white;
  }
  &:hover ${Line} {
    border-top: 1px solid yellow;
  }
`;

export default WorkBox;
