import React from "react";
import styled, { keyframes } from "styled-components";
// import { ReactComponent as YesoOne } from "../assets/icons/yesoone.svg";
// import { ReactComponent as YesoThree } from "../assets/icons/yesothree.svg";
// import { ReactComponent as YesoFour } from "../assets/icons/yesofour.svg";
import { ReactComponent as Principa } from "../assets/icons/principal.svg";
import { ReactComponent as All } from "../assets/icons/allicon.svg";
import { ReactComponent as Fac } from "../assets/icons/face.svg";
import { ReactComponent as What } from "../assets/icons/whatsapp.svg";
import { ReactComponent as Ins } from "../assets/icons/insta.svg";
import { ReactComponent as Con } from "../assets/icons/contact.svg";

const shakeAnimation = keyframes`
   10%, 90% {
    transform: translate3d(0, -3px, 0);
  }
  
  20%, 80% {
    transform: translate3d(0, 4px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -6px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 6px, 0);
  }
`;

const shakeAnimationTwo = keyframes`
   10%, 90% {
    transform: translate3d(-3px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(4px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-6px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(6px, 0, 0);
  }
`;

const IconsContainer = styled.div`
  margin-top: 20px;
  animation: ${shakeAnimationTwo} 10s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
  display:flex;
`;

const ContPrincipal = styled.div`
    display:flex;
    margin-top: 20px;
    animation: ${shakeAnimation} 10s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
    @media (max-width: 813px){
      width:80%;
    }

`;

const RedSocialF = styled.a`
  margin: 5px 20px 5px 5px;
  cursor: pointer;
`;
const Numero = styled.div`
  margin: 5px 20px 5px 5px;
  cursor: pointer;
`;
const RedSocialI = styled.a`
  margin-right: 20px;
  cursor: pointer;
`;

// const IconOne = () => <Container><YesoOne /> </Container>;
// const IconThree = () => <Container><YesoThree /> </Container>;
// const IconFour = () => <Container><YesoFour /> </Container>;
const AllIcons = () => (
  <IconsContainer>
    <All />
  </IconsContainer>
);
const Principal = () => (
  <ContPrincipal>
    <Principa />
  </ContPrincipal>
);
const Contact = ({ href = "" }) => (
  <RedSocialF href={href}>
    <Con />
  </RedSocialF>
);
const Face = () => (
  <RedSocialI href="https://www.facebook.com/placdry" target="_blank">
    <Fac />
  </RedSocialI>
);
const Insta = () => (
  <RedSocialF a href="https://www.instagram.com/placdry.uy" target="_blank">
    <Ins />
  </RedSocialF>
);
const Number = () => (
  <Numero>
    <What />
  </Numero>
);

export {
  /*IconOne, IconThree, IconFour,*/ Principal,
  AllIcons,
  Face,
  Insta,
  Contact,
  Number
};
