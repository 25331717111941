import React from "react";
import Images from "./components/Images";
import Section from "../../components/Section";
import styled from "styled-components";

// URL DE IMAGES
import liso26x52 from "../../assets/images/liso26x52.jpeg";
import marmol26x52 from "../../assets/images/marmol26x52.jpeg";
import mediterraneo61x61 from "../../assets/images/mediterraneo61x61.jpeg";
import modeloLadrillo56x56 from "../../assets/images/modeloLadrillo56x56.jpeg";
import molduraTerminacionFrisos from "../../assets/images/molduraTerminacionFrisos.jpeg";
import ondas50x50 from "../../assets/images/ondas50x50.jpeg";
import otroModelo61x61 from "../../assets/images/otroModelo61x61.jpeg";
import piedra26x52 from "../../assets/images/piedra26x52.jpeg";
import pielNaranja61x61 from "../../assets/images/pielNaranja61x61.jpeg";
import pizarraFina44x36 from "../../assets/images/pizarraFina44x36.jpeg";
import pizarraGruesa44x36 from "../../assets/images/pizarraGruesa44x36.jpeg";
import rombos50x50 from "../../assets/images/rombos50x50.jpeg";
import sol61x61 from "../../assets/images/sol61x61.jpeg";
import travertino26x52 from "../../assets/images/travertino26x52.jpeg";
import { fadeInDown } from "../../extras/Keyframes";
// FIN URL DE IMAGES

const IMAGES = [
  { url: liso26x52, description: "Modelo liso 26x52" },
  { url: marmol26x52, description: "Modelo marmol 26x52" },
  { url: mediterraneo61x61, description: "Modelo mediterraneo 61x61" },
  { url: modeloLadrillo56x56, description: "Modelo ladrillo 56x56" },
  {
    url: molduraTerminacionFrisos,
    description: "Modura terminación frisos 52x0.35 y 66x6",
  },
  { url: otroModelo61x61, description: "Modelo ubeda 61x61" },
  { url: ondas50x50, description: "Modelo ondas 50x50" },
  { url: piedra26x52, description: "Modelo piedra 26x52" },
  { url: pielNaranja61x61, description: "Modelo piel naranja 61x61" },
  { url: pizarraFina44x36, description: "Modelo pizarra fina 44x36" },
  { url: pizarraGruesa44x36, description: "Modelo pizarra gruesa 44x36" },
  { url: rombos50x50, description: "Modelo rombos 50x50" },
  { url: sol61x61, description: "Modelo sol 61x61" },
  { url: travertino26x52, description: "Modelo travertino 26x52" },
];

const Products = ({ id = "section-images" }) => {
  return (
    <Animation>
      <Section.Container id={id}>
        <Line />
        <Section.Title>Nuestros Productos</Section.Title>
        <Text>
          <Section.Description>
            Por sus propiedades físicas, las placas absorben la humedad de las
            paredes creando un canal de evaporación imperceptible para el agua
            que que se filtra desde los cimientos u otros orígenes.
            <br />
            <br />
            Este proceso es permanente mientras el ambiente permita el secado de
            la placa (ventilación o deshumidificación). En tales condiciones la
            placa se mantendrá inalterable de forma indefinida, por lo que no
            será necesario pintarlas ni realizarles ningún mantenimiento.
          </Section.Description>
        </Text>
        <Images images={IMAGES} />
      </Section.Container>
    </Animation>
  );
};
const Animation = styled.div`
  animation: ${fadeInDown} 2.5s;
`;

const Text = styled.div`
  padding: 0px 100px;
  @media (max-width: 813px) {
    padding: 10px;
  }
`;

const Line = styled.hr`
  width: 80%;
  height: 1px;
  border: none;
  background-color: #efefef;
  margin-bottom: 20px;
`;

export default Products;
