import React from "react";
import styled from "styled-components";
import { Face, Insta, Contact, Number } from "../sections/Icons";

const Footer = ({ id = "section-contact" }) => {
  return (
    <Container id={id}>
      <Row>
        <Contact href="mailto:daar1963@hotmail.com" />
        <p>daar1963@hotmail.com</p>
      </Row>
      <Row>
        <NavigationWpp a href="https://api.whatsapp.com/send?phone=59899542129&text=Hola%20%20PlacDry" target="_blank">
        <Number />
        
        <p> 099542129 - 098985584</p>
        </NavigationWpp>
      </Row>
      <Row>
        <Face />
        <Insta />
        <p>© 2020 PLAC-DRY todos los derechos reservados</p>
      </Row>
    </Container>
  );
};

const NavigationWpp = styled.a`
  display:flex;
  color:white;
  align-items: center;
  text-decoration: none;
  flex-direction: row;
`;


const Container = styled.div`
  background-color: black;
  color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 20px;

`;

export default Footer;
