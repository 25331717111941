import React from "react";
import Section from "../components/Section";
import styled from "styled-components";
import WorkBox from "../components/WorkBox";

const WORKS = [
  {
    title: "Domicilios",
    img: "hogar.png",
    description: `Revestimiento de paredes en domicilios. Se puede aplicar en
paredes completas o en forma de friso hasta la altura afectada y
continuación de azulejos en baños y cocinas.`,
    url: "mailto:daar1963@hotmail.com",
  },
  {
    title: "Negocios",
    img: "negocios.png",
    description: `Actualmente equipamos con nuestras placas de yeso a negocios de
    todo tipo dentro de la región.`,
    url: "mailto:daar1963@hotmail.com",
  },
  {
    title: "Oficinas",
    img: "oficinas.png",
    description: `Día a día más oficinas utilizan placas de yeso para la estética y
    contención de humedad.`,
    url: "mailto:daar1963@hotmail.com",
  },
];

const WorkDone = ({ id = "section-work" }) => {
  return (
    <Body>
      <Section.Container id={id}>
        <Line />
        <Section.Title>Trabajos realizados</Section.Title>
        <Des>
          <Section.Description>
            A continuación algunos ejemplos de trabajos realizados. En caso de
            necesitar más muestras, comuníquese con nosotros, y con gusto le
            mostraremos más fotos de nuestros trabajos realizados.
          </Section.Description>
        </Des>
        <SectionBox>
          {WORKS.map((item, index) => (
            <WorkBox
              title={item.title}
              description={item.description}
              img={item.img}
              url={item.url}
              key={String(index)}
            />
          ))}
        </SectionBox>
      </Section.Container>
    </Body>
  );
};

const Des = styled.div`
  padding: 0px 50px;
  @media (max-width: 813px) {
    padding: 10px;
  }
`;

const Body = styled.div`
  margin-bottom: 80px;
`;

const SectionBox = styled.div`
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 30px;
  @media (max-width: 813px) {
    flex-direction: column;
  }
`;

const Line = styled.hr`
  width: 80%;
  height: 1px;
  border: none;
  background-color: #efefef;
  margin-bottom: 40px;
  margin-top: 60px;
`;

export default WorkDone;
