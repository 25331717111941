import React from "react";
import styled from "styled-components";
import navigate from "../extras/navigate";
import { Principal, AllIcons } from "../sections/Icons";
import Menu from "./Menu";
import Background from "../assets/images/background.png";
import {
  fadeInUp,
  fadeInLeft,
  fadeInRight,
  Zoom,
  ZoomIn,
} from "../extras/Keyframes";

const Head = ({ id = "section-main" }) => {
  const handleClick = (section) => () => {
    navigate(section, 400);
  };
  return (
    <>
      <Menu />
      <Body id={id}>
        <ContainerOne>
          <Text>PLAC-DRY</Text>
          <Subtitle> Placas antihumedad </Subtitle>
          <Description>
            Esta placa se pega directo a la pared sin necesidad de perfilería,
            el proceso de colocación es sumamente rápido, práctico y limpio (sin
            polvo en el ambiente).
            <br />
            <br /> No existe de momento otra solución más eficaz para problemas
            de humedades crónicas.
            <br />
            <br /> No necesita pintura ni mantenimiento si los ambientes se
            ventilan adecuadamente.
            <br />
            <br /> Por sus características mejoran sustancialmente la
            habitabilidad del hogar.
            <br />
            <br /> Ademas las placas son ignífugas y aislantes térmicas, mejoran
            la acústica de los ambientes, y por ser inertes no afectan a
            personas con alergias.
            <br />
            <br />
            Otorgamos garantías de mantenimiento por 10 años a clientes que
            contratan nuestro servicio de colocación.
          </Description>
          <Contactar onClick={handleClick("section-contact")}>
            Contactar
          </Contactar>
        </ContainerOne>
        <ContainerTwo>
          <Principal />
          <Icons>
            <AllIcons />
          </Icons>
        </ContainerTwo>
      </Body>
    </>
  );
};

const Icons = styled.div`
  @media (max-width: 813px) {
    width: 100%;
  }
`;

const Description = styled.p`
  color: #6e6e6e;
  margin-bottom: 30px;
  animation: ${fadeInUp} 3s;
  @media (max-width: 813px) {
    padding: 10px;
  }
`;

const Contactar = styled.button`
  width: 200px;
  cursor: pointer;
  outline: none;
  color: white;
  background: #f50b0b;
  height: 50px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 1em;
  border: solid 2px #f50b0b;
  border-radius: 50px;
  transition: all 0.5s;
  animation: ${ZoomIn} 3s;
  :hover {
    color: #f50b0b;
    background: white;
    transform: scale(1.05);
  }
`;

const Subtitle = styled.h3`
  font-size: 2em;
  margin: 0px;
  margin-bottom: 10px;
  font-weight: normal;
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  animation: ${fadeInRight} 2.5s;
  @media (max-width: 813px) {
    font-size: 1.7em;
  }
`;

const Text = styled.p`
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  font-size: 4em;
  margin: 0px;
  color: #f50b0b;
  margin-top: 20px;
  animation: ${fadeInLeft} 2.5s;
  @media (max-width: 813px) {
    font-size: 3em;
  }
`;

const Body = styled.div`
  display: flex;
  flex-direction: row;
  color: black;
  background-image: url(${Background});
  animation: ${Zoom} 1s;
  width: 100%;
  @media (max-width: 813px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ContainerOne = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 20px 30px 50px;
`;

const ContainerTwo = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
  text-align: left;
  padding: 30px 20px;
  width: 70%;
  display: flex;
  animation: ${fadeInRight} 3.5s;
`;

export default Head;
