import React from "react";
import styled from "styled-components";
import Head from "./sections/Head";
import Products from "./sections/Products";
import Who from "./sections/Who";
import Footer from "./sections/Footer";
import WorkDone from "./sections/WorkDone";

function App() {
  return (
    <Container>
      <Head id="section-head" />
      <Who id="section-who" />
      <Products id="section-products" />
      <WorkDone id="section-work" />
      <Footer id="section-contact" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: white;
`;

export default App;
