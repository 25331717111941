import styled from "styled-components";
import { fadeInLeft } from "../extras/Keyframes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Title = styled.p`
  text-align: left;
  font-size: 2em;
  font-family: "helvetica";
  font-weight: bold;
`;

const Description = styled.p`
  color: #6e6e6e;
  font-size: 1em;
  margin-left: 20px;
  margin-right: 20px;
  text-align: left;
  animation: ${fadeInLeft}3.5s;
  @media (max-width: 813px) {
    padding: 13px;
  }
`;

export default { Title, Container, Description };
