import React from "react";
import Section from "../components/Section";
import styled from "styled-components";
import { fadeInLeft, fadeInRight } from "../extras/Keyframes";
// import Backgroundd from "../assets/images/background2.png";

const Who = ({ id = "section-who" }) => {
  return (
    <>
      <Line />
      <Body>
        <ContainerTwo>
          <Section.Container id={id}>
            <LeftTitle>
              <Section.Title>¿Quiénes somos?</Section.Title>
            </LeftTitle>
            <Section.Description>
              Industria Uruguaya iniciada en 2014.
              <br />
              <br /> Fabricamos, colocamos y vendemos placas Antihumedad desde
              hace mas de 6 años.
              <br />
              <br /> Cientos de trabajos realizados en todo este tiempo con
              nuestras placas avalan la calidad del producto.
            </Section.Description>
            <FaceBook a href="https://www.facebook.com/placdry" target="_blank">
              Siguenos en Facebook para ver más de nuestros trabajos
            </FaceBook>
          </Section.Container>
        </ContainerTwo>
        <ContainerP>
          <Picture src="who.png" />
        </ContainerP>
      </Body>
    </>
  );
};

const LeftTitle = styled.div`
  text-align: left;
  width: 100%;
  margin-left: 40px;
  animation: ${fadeInLeft} 2.5s;
`;

const Body = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: 813px) {
    flex-direction: column;
  }
`;

const FaceBook = styled.a`
  display: flex;
  border-radius: 4px;
  color: #0e67bf;
  width: 100%;
  font-size: 0.8em;
  font-family: "helvetica";
  text-align: left;
  margin-left: 40px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.5s;
  :hover {
    transform: scale(1.05);
  }
  @media (max-width: 813px) {
    margin-left: 70px;
    font-size: 0.6em;
    margin-bottom: 20px;
  }
`;

const ContainerP = styled.div`
  width: 50%;
  flex-direction: column;
  justify-content: left;
  align-items: center;
  display: flex;
  animation: ${fadeInRight} 2.5s;
  @media (max-width: 813px) {
    width: 90%;
  }
`;
const ContainerTwo = styled.div`
  width: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: ${fadeInLeft} 2.5s;
  @media (max-width: 813px) {
    width: 90%;
  }
`;
const Picture = styled.img`
  width: 75%;
`;
const Line = styled.hr`
  width: 80%;
  height: 1px;
  border: none;
  background-color: #efefef;
  margin-bottom: 100px;
  margin-top: 140px;
  @media (max-width: 813px) {
    margin: 20px 0 20px 0;
  }
`;

export default Who;
