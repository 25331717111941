import React from "react";
import styled from "styled-components";
import navigate from "../extras/navigate";
import MenuContainer from "../components/MenuContainer";

const Menu = () => {
  const handleClick = (section) => () => {
    navigate(section, 400);
  };
  return (
    <MenuContainer>
      <Text onClick={handleClick("section-head")}>Inicio</Text>
      <Text onClick={handleClick("section-who")}>¿Quiénes somos?</Text>
      <Text onClick={handleClick("section-products")}>Nuestros productos</Text>
      <Text onClick={handleClick("section-work")}>Trabajos realizados</Text>
      <Text onClick={handleClick("section-contact")}>Contacto</Text>
    </MenuContainer>
  );
};

const Text = styled.h3`
  display: flex;
  cursor: pointer;
  align-items: center;
  height: 60px;
  margin: 5px 20px;
  padding: 10px;
  font-weight: normal;
  justify-content: center;
`;

export default Menu;
